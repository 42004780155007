<template>
  <v-autocomplete v-model="selectedAccountID" :items="accounts" :loading="loading" item-text="name" item-value="account_id" :label="label" @change="changed" 
    prepend-icon="account_balance" :required="mandatory" :rules="[v => !mandatory || !!v || label + ' is required']" :clearable="!mandatory">
    <template v-slot:append-outer>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-dots-vertical</v-icon>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-title @click="refresh()">Refresh accounts</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'BingAccountAutoComplete',

  props: {
    value: { type: Number, required: true }, // v-model
    label: { type: String, required: false, default: 'Account' },
    mandatory: { type: Boolean, required: false, default: true },
  },

  data () {
    return {
      loading: false,
      refreshEndpoint: '/a/bing/accounts/refresh',
    }
  },

  computed: {
    accounts () {
        return this.$store.state.bing.accounts
    },
    
    // v-model
    selectedAccountID: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    changed: function () {
      this.$emit('changed')
    },

    refresh () {
      this.$emit('refreshing')
      this.loading = true
      this.selectedAccountID = 0

      this.$http.post(this.refreshEndpoint).then(resp => {
        this.$store.dispatch('bing/loadAccounts')
        .catch(errResp => {
          this.$store.commit('system/setAPIError', { method: 'GET', url: 'bing/loadAccounts', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
        })
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.refreshEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },
  },
}
</script>
